import React from "react";
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

function extractTextFromPath(path) {
    const regex = /\/static\/media\/(.*)\.[a-z0-9]+$/i;
    const match = path.match(regex);
    if (match) {
      let extractedText = match[1];
      if (/(img|IMG|Img)/.test(extractedText)) {
        return '';
      }
      extractedText = extractedText.split('.')[0];
      return extractedText;
    } else {
      return null;
    }
  }

function Section(props){
    return(
        <div className="flex flex-col align-middle justify-center">
            <div className="md:ml-5 mx-4 md:mx-0 mb-10">
                <h2 className='text-4xl md:text-5xl font-bold mb-10'>{props.title}</h2>
                <p className='mb-5 italic'>{props.p1}</p>
                <p className='mb-5'>{props.p2}</p>
                <p className='mb-5'>{props.p3}</p>
                {props.children}
            </div>
            <div className="flex flex-row align-middle justify-center mb-10 items-center flex-wrap">
                {props.list.map((file, index) => {
                /*Images use 1/2 of the space avalible but videos take the full width of the space avalible*/
                if (file.endsWith('.jpg')) {
                    return (
                    <div className='mb-5 md:ml-5 mx-4 md:mx-0'>
                    <Zoom>
                        <img key={index} src={file} alt='' className='md:max-w-2xl md:max-h-screen' loading="lazy"/>
                    </Zoom>
                        <span className='text-newgrey font-normal'>{extractTextFromPath(file)}</span>
                    </div>
                    );
                } else if (file.includes('youtube')) {
                    return (
                        <div key={index} className="w-3/5">
                            <div className="aspect-video">
                            <iframe
                                title={`Video ${index + 1}`}
                                className="w-full h-full"
                                src={file}
                                frameBorder="0"
                                allowFullScreen
                            ></iframe>
                            </div>
                        </div>
                    );
                } else {
                    // Render a placeholder or custom component for other file types
                    return (
                    <div key={index}>
                        File type not supported: {file}
                    </div>
                    );
                }
                })}
            </div>
        </div>
    );
}

export default Section;