import React from 'react';
import { BrowserRouter, Routes, Route, HashRouter} from 'react-router-dom';
import Layout from './components/Layout.js';
import Column from './components/Column.js';
import Artist from './components/Artist.js';
import About from './components/About.js';
import logo from "./assets/flavor/FrownLogoOrange.png";
import Videos from './components/Videos.js';
import Sections_Column from './components/Sections_Column.js';
import Section from './components/Section.js';

/*All image folders are called from here, since this has to be a static website all images are loaded this way*/

/* videos need to be called from youtube to avoid big files */

const alternative_processes = require.context('./assets/artist_practice/alternative_processes/', true);
const alternative_processesList = alternative_processes.keys().map(image => alternative_processes(image));

const BW_film_photography = require.context('./assets/artist_practice/BW_film_photography/', true);
const BW_film_photographyList = BW_film_photography.keys().map(image => BW_film_photography(image));

const color_film_photography = require.context('./assets/artist_practice/color_film_photography/', true);
const color_film_photographyList = color_film_photography.keys().map(image => color_film_photography(image));

const ikebana = require.context('./assets/artist_practice/ikebana/', true);
const ikebanaList = ikebana.keys().map(image => ikebana(image));

const in_relation_to_series = require.context('./assets/artist_practice/in_relation_to_series/', true);
const in_relation_to_seriesList = in_relation_to_series.keys().map(image => in_relation_to_series(image));

const installation_performance = require.context('./assets/artist_practice/installation_performance/', true);
const installation_performanceList = installation_performance.keys().map(image => installation_performance(image));

const nightscapes = require.context('./assets/artist_practice/nightscapes/', true);
const nightscapesList = nightscapes.keys().map(image => nightscapes(image));

const photographic_relationships_collaboration = require.context('./assets/artist_practice/photographic_relationships_collaboration/', true);
const photographic_relationships_collaborationList = photographic_relationships_collaboration.keys().map(image => photographic_relationships_collaboration(image));

const printmaking_doodles_and_painting = require.context('./assets/artist_practice/printmaking_doodles_and_painting/', true);
const printmaking_doodles_and_paintingList = printmaking_doodles_and_painting.keys().map(image => printmaking_doodles_and_painting(image));

const archival_unit = require.context('./assets/artist_practice/installation_performance/archival_unit/', true);
const archival_unitList = archival_unit.keys().map(image => archival_unit(image));
archival_unitList.push('https://www.youtube.com/embed/s4z1F90d7ME');

const remote_unit = require.context('./assets/artist_practice/installation_performance/remote_unit/', true);
const remote_unitList = remote_unit.keys().map(image => remote_unit(image));

const sitters = require.context('./assets/artist_practice/installation_performance/sitters/', true);
const sittersList = sitters.keys().map(image => sitters(image));

const building_a_ladder = require.context('./assets/artist_practice/installation_performance/building_a_ladder/', true);
const building_a_ladderList = building_a_ladder.keys().map(image => building_a_ladder(image));

const conveyor = require.context('./assets/artist_practice/installation_performance/conveyor/', true);
const conveyorList = conveyor.keys().map(image => conveyor(image));

const erasure = require.context('./assets/artist_practice/installation_performance/erasure/', true);
const erasureList = erasure.keys().map(image => erasure(image));
erasureList.push('https://www.youtube.com/embed/qdjAC2RxPN0');

const our_return_to_the_redwoods = require.context('./assets/artist_practice/installation_performance/our_return_to_the_redwoods/', true);
const our_return_to_the_redwoodsList = our_return_to_the_redwoods.keys().map(image => our_return_to_the_redwoods(image));

//Commercial Work

const barbara_earl_thomas_talk_artsfund = require.context('./assets/commercial_work/events/barbara_earl_thomas_talk_artsfund', true);
const barbara_earl_thomas_talk_artsfundList = barbara_earl_thomas_talk_artsfund.keys().map(image => barbara_earl_thomas_talk_artsfund(image));

const chusid = require.context('./assets/commercial_work/events/chusid', true);
const chusidList = chusid.keys().map(image => chusid(image));

const katie_bunn_marcuse = require.context('./assets/commercial_work/events/katie_bunn_marcuse', true);
const katie_bunn_marcuseList = katie_bunn_marcuse.keys().map(image => katie_bunn_marcuse(image));

const kolya = require.context('./assets/commercial_work/events/kolya', true);
const kolyaList = kolya.keys().map(image => kolya(image));

const rebecca_frye_Chapel_PCNW_field_trip = require.context('./assets/commercial_work/events/rebecca_frye-Chapel-PCNW_field_trip', true);
const rebecca_frye_Chapel_PCNW_field_tripList = rebecca_frye_Chapel_PCNW_field_trip.keys().map(image => rebecca_frye_Chapel_PCNW_field_trip(image));

const sperling_and_dr_mille_henry_visit = require.context('./assets/commercial_work/events/sperling_and_dr_mille_henry_visit', true);
const sperling_and_dr_mille_henry_visitList = sperling_and_dr_mille_henry_visit.keys().map(image => sperling_and_dr_mille_henry_visit(image));

const whitney_lynn = require.context('./assets/commercial_work/events/whitney_lynn', true);
const whitney_lynnList = whitney_lynn.keys().map(image => whitney_lynn(image));

const JLG_honors_opening_1 = require.context('./assets/commercial_work/exhibitions/JLG_honors_opening_1', true);
const JLG_honors_opening_1List = JLG_honors_opening_1.keys().map(image => JLG_honors_opening_1(image));

const medium_BA_graduate_exhibition_opening = require.context('./assets/commercial_work/exhibitions/medium_BA_graduate_exhibition_opening', true);
const medium_BA_graduate_exhibition_openingList = medium_BA_graduate_exhibition_opening.keys().map(image => medium_BA_graduate_exhibition_opening(image));

const MFA_reception_henry_art_gallery = require.context('./assets/commercial_work/exhibitions/MFA_reception_henry_art_gallery', true);
const MFA_reception_henry_art_galleryList = MFA_reception_henry_art_gallery.keys().map(image => MFA_reception_henry_art_gallery(image));

const photographic_relationships = require.context('./assets/commercial_work/personal_projects/photographic_relationships', true);
const photographic_relationshipsList = photographic_relationships.keys().map(image => photographic_relationships(image));

//Portraits to use in all main section (gallery, events, exhibitions, personal projects)

const gallery_portraits = require.context('./assets/artist_practice/portraits/', true);
const gallery_portraitsList = gallery_portraits.keys().map(image => gallery_portraits(image));

const events_portraits = require.context('./assets/commercial_work/events/portraits', true);
const events_portraitsList = events_portraits.keys().map(image => events_portraits(image));

const exhibitions_portraits = require.context('./assets/commercial_work/exhibitions/portraits', true);
const exhibitions_portraitsList = exhibitions_portraits.keys().map(image => exhibitions_portraits(image));

const personal_projects_portraits = require.context('./assets/commercial_work/personal_projects/portraits', true);
const personal_projects_portraitsList = personal_projects_portraits.keys().map(image => personal_projects_portraits(image));

/*
Images must be jpg to work properly (since images are really big compresion is a must, also thats the format i decided to use, this can easily be changed)

The following code is used to extract the name of the image from the url, this is used to display the name of the image in the gallery
*/

function App() {


  return (
        <HashRouter>
          <Layout>
            <Routes>
              {/*Gallery Index for each section, / refers to the main website, in this case artist gallery*/}
              <Route path='/about' element={<About logo={logo}/>}/>
              <Route path='/' element={<Artist list={gallery_portraitsList} title='Galleries' logo={logo}/>}/>
              <Route path='/events' element={<Artist list={events_portraitsList} title='Events' logo={logo}/>}/>
              <Route path='/exhibitions' element={<Artist list={exhibitions_portraitsList} title='Exhibitions' logo={logo}/>}/>
              <Route path='/personal_projects' element={<Artist list={personal_projects_portraitsList} title='Personal Projects' logo={logo}/>}/>
              {/*
              each gallery is sent to the column component to render all images inside the folder

              this is the list of galleries of artist practice, each gallery is a folder inside the assets folder
              */}
              <Route path='/alternative_processes' element={<Column list={alternative_processesList} date={null} title='Alternative Processes'/>}/>
              <Route path='/BW_film_photography' element={<Column list={BW_film_photographyList} date={null} title='BW Film Photography'/>}/>
              <Route path='/color_film_photography' element={<Column list={color_film_photographyList} date={null} title='Color Film Photography'/>}/>
              <Route path='/ikebana' element={<Column list={ikebanaList} date={null} title='Ikebana'/>}/>
              <Route path='/in_relation_to_series' element={<Column list={in_relation_to_seriesList} date={null} title='In Relation to Series'/>}/>
              <Route path='/installation_performance' element={
                <Sections_Column title='Installation Performance'>
                  <Section title='Archival Unit (2022)' 
                  p1='The Sitters, Remote Imaging Machine, Conveyor.' 
                  p2='an antiquated system that operates contradictory to its primary directive, creating a defective feedback loop. In this archival process by digital and analog means, documentation and preservation is followed by erasure. This begs the question, who or what is being erased in this process?' 
                  p3='this unit is manned in a performance of the process. https://youtu.be/s4z1F90d7ME' 
                  list={archival_unitList}/>
                  <Section title='Remote Imaging Machine (2022)' 
                  p1='' 
                  p2='flimsy tripod, cardboard box, DSLR camera, Audio/Visual digital output cable, motherboard, CPU, CPU fan, PSU, RAM, SSD, Wi-Fi adapter, headphones, power cables, 50ft HDMI cable, desk, rolling chair, monitor, inkjet printer, mouse and keyboard.' 
                  p3='remotely documenting.' 
                  list={remote_unitList}/>
                  <Section title='The Sitters (2022)' 
                  p1='' 
                  p2='8 exposed photo emulsion paintings, garbage bin, pitcher, sponge, soapy water.' 
                  p3='prep for whitewashing.' 
                  list={sittersList}/>
                  <Section title='Conveyor (2022)' 
                  p1='' 
                  p2='metal stool, hot light, contact sheet proofer, inkjet print on translucent film, developing silver gelatin print, institutionally white pedestal, UWPHOTO spot light, destroyed/erased silver gelatin prints.' 
                  p3='conveying and displaying.' 
                  list={conveyorList}/>
                  <Section title='Our Return to the Redwoods (2022)' 
                  p1='' 
                  p2='bed, light table, inkjet prints of film from the same roll, failed silver gelatin prints' 
                  p3='' 
                  list={our_return_to_the_redwoodsList}>
                    <div className=''>
                      <p className='italic'>
                      So when you praise something<br/>

                      Alright? It lives<br/>

                      When the spirits praise us, we live<br/>

                      But their praise is our life<br/>

                      Their praise is our heartbeat<br/>

                      Their praise is the grass growing<br/>

                      At least I get to live<br/>

                      I never think when it'd die<br/>

                      You'd have to grieve the hell out of it<br/>

                      Because if you don't grieve it, then it never was really alive<br/>

                      It didn't live, it's already dead<br/>

                      And that's what terrifies the hell out of us<br/>

                      If you have two centuries of people that haven't grieved the things that they loved, and they left properly, where does that grief go?<br/> 
                      </p>
                    </div>
                  </Section>
                  <Section title='Building a Ladder (2022) *with Manfrotto' 
                  p1='' 
                  p2='5 Manfrotto tripods, 120mm film negatives,  flashlight, sheet music wire stand, table, 6 developing trays, 3 dark boxes, silver gelatin prints.' 
                  p3='Antiarchival?' 
                  list={building_a_ladderList}/>
                  <Section title='Erasure' 
                  p1='' 
                  p2='' 
                  p3='' 
                  list={erasureList}/>
                  
                </Sections_Column>
              }/>
              <Route path='/nightscapes' element={<Column list={nightscapesList} date={null} title='Nightscapes'/>}/>
              <Route path='/photographic_relationships_collaboration' element={<Column list={photographic_relationships_collaborationList} date={null} title='Photographic Relationships Collaboration'/>}/>
              <Route path='/printmaking_doodles_and_painting' element={<Column list={printmaking_doodles_and_paintingList} date={null} title='Printmaking Doodles and Painting'/>}/>
              <Route path='/video' element={<Videos
                                                              title="Videos"
                                                              date={null}
                                                              videoLinks={[
                                                                "https://www.youtube.com/embed/kP7dAbsfmDE",
                                                                "https://www.youtube.com/embed/fWLitcKIGHA",
                                                                "https://www.youtube.com/embed/2WE5j9KlmJE",
                                                              ]}
                                                            />
                                                            }/>
              {
              /*List of galleries for events, exhibitions and personal projects

              events*/
              }
              <Route path='/events/barbara_earl_thomas_talk_artsfund' element={<Column list={barbara_earl_thomas_talk_artsfundList} date={null} title='Barbara Earl Thomas talk ArtsFund'/>}/>
              <Route path='/events/chusid' element={<Column list={chusidList} date={"05-18-22"} title='Chusid'/>}/>
              <Route path='/events/katie_bunn-Marcuse' element={<Column list={katie_bunn_marcuseList} date={"05-16-22"} title='Katie Bunn-Marcuse'/>}/>
              <Route path='/events/kolya' element={<Column list={kolyaList} date={"05-09-22"} title="Kolya's class at UW"/>}/>
              <Route path='/events/rebecca_frye-Chapel-PCNW_field_trip' element={<Column list={rebecca_frye_Chapel_PCNW_field_tripList} date={"05-18-22"} title='Rebecca Frye-Chapel-PCNW Field Trip'/>}/>
              <Route path='/events/sperling_and_dr_mille_henry_visit' element={<Column list={sperling_and_dr_mille_henry_visitList} date={"07-14-22"} title='Sperling and Dr Mille Henry visit'/>}/>
              <Route path='/events/whitney_lynn' element={<Column list={whitney_lynnList} date={"05-17-22"} title='Whitney Lynn'/>}/>
              {//exhibitions
              }
              <Route path='/exhibitions/JLG_honors_opening_1' element={<Column list={JLG_honors_opening_1List} date={"05-17-22"} title='JLG Honors Opening 1'/>}/>
              <Route path='/exhibitions/medium_BA_graduate_exhibition_opening' element={<Column list={medium_BA_graduate_exhibition_openingList} date={"05-17-22"} title='Medium BA Graduate Exhibition Opening'/>}/>
              <Route path='/exhibitions/MFA_reception_henry_art_gallery' element={<Column list={MFA_reception_henry_art_galleryList} date={"06-3-22"} title='MFA Reception Henry art Gallery'/>}/>
              {//personal projects
              }
              <Route path='/personal_projects/photographic_relationships' element={<Column list={photographic_relationshipsList} date={null} title='Photographic Relationships'/>}/>
            </Routes>
          </Layout>
        </HashRouter>
  );
}

export default App;