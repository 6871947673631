import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import instagram from "./new_assets/instagram.svg";
import linkeind from "./new_assets/linkedin.svg";
import logo from "./new_assets/FrownLogo.png";

function Sidebar() {
    useEffect(() => {
        const button = document.querySelector('#menu-button');
        const menu = document.querySelector('#menu');
    
        const handleMenuClick = () => {
          menu.classList.toggle('hidden');
        };
    
        if (button && menu) {
          button.addEventListener('click', handleMenuClick);
        }
    
        // Clean up the event listener when the component unmounts
        return () => {
          if (button && menu) {
            button.removeEventListener('click', handleMenuClick);
          }
        };
      }, []);

  return (
    <nav className="">
      <ul className="hidden md:flex flex-col py-10 px-7 border-b md:border-b-0 md:border-l border-newgrey h-screen top-0 right-0 fixed w-56">
        <h2 className="text-2xl font-normal">Artist Practice</h2>
        <li className='font-light'><Link to="/">Galleries</Link></li>
        <li className='font-light'><Link to="/video">Videos</Link></li>
        <li className="border-b border-newgrey my-10 h-0"></li>
        <h2 className="text-2xl font-normal">Comercial Work</h2>
        <li className='font-light'><Link to="/events">Events</Link></li>
        <li className='font-light'><Link to="/exhibitions">Exhibitions</Link></li>
        <li className='font-light'><Link to="/personal_projects">Personal Projects</Link></li>
        <li className="border-b border-newgrey my-10 h-0"></li>
        <li className='font-light'><Link to="/about">About Me</Link></li>
        <div className='flex flex-col-reverse h-full w-full'>
          <div className='flex flex-col w-full justify-between items-end'>
            <li className='mb-5 text-2xl'>
              <a href="https://www.instagram.com/gage.la/?hl=en" className="">
                <img src={instagram} className="object-contain h-8 mr-2 brightness-0" alt="instagram" />
              </a>
            </li>
            <li className='mb-5 text-2xl'>
              <a href="https://www.linkedin.com/in/gage-lamberson-85a0b0210/" className="">
                <img src={linkeind} className="object-contain h-8 mr-2 brightness-0" alt="linkedin" />
              </a>
            </li>
          </div>
        </div>
      </ul>

      <div className="border-b border-newgrey py-5 px-8 flex flex-row md:hidden">
        <div className="w-0 lg:w-1/5">

        </div>
        <div className="md:w-0 w-1/2">
          <div className="flex items-center justify-start gap-x-6 px-2.5 py-2 md:px-7 md:py-2.5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              id="menu-button"
              className="h-6 w-6 cursor-pointer lg:hidden block"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
          </div>
          <div className="hidden md:hidden w-full lg:items-center lg:justify-center lg:w-3/5" id="menu">
            <ul className="pt-4">
                <h2 className="text-2xl font-normal px-2.5">Artist Practice</h2>
                <li className='font-light px-2.5'><Link to="/">Galleries</Link></li>
                <li className='font-light px-2.5'><Link to="/video">Videos</Link></li>
                <li className="border-b border-newgrey my-10 h-0 w-3/4 px-2.5"></li>
                <h2 className="text-2xl font-normal px-2.5">Comercial Work</h2>
                <li className='font-light px-2.5'><Link to="/events">Events</Link></li>
                <li className='font-light px-2.5'><Link to="/exhibitions">Exhibitions</Link></li>
                <li className='font-light px-2.5'><Link to="/personal_projects">Personal Projects</Link></li>
                <li className="border-b border-newgrey my-10 h-0 w-3/4 px-2.5"></li>
                <li className='font-light px-2.5 mb-5'><Link to="/about">About Me</Link></li>
            </ul>
          </div>
        </div>
        <div className="lg:w-1/5 w-1/2">
          <Link to="/" className="flex flex-row items-center justify-end gap-x-6 h-10 overflow-visible relative">
            <img src={logo} className='h-28 absolute top-3 transform -translate-y-1/2' />
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Sidebar;
