import React, { useState } from 'react';
import { Link } from 'react-router-dom';

function capitalizeSentence(sentence) {
  // Split the sentence into an array of words
  const words = sentence.split('_');

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(word => word.charAt(0).toUpperCase() + word.slice(1));

  // Join the words back into a sentence
  const capitalizedSentence = capitalizedWords.join(' ');

  // Return the capitalized sentence
  return capitalizedSentence;
}

function Gallery(props) {
  const [isHovered, setIsHovered] = React.useState(false);
  
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    /* \
    original:
    border border-newgrey p-5 w-max h-max max-w-2xl relative mb-10 ml-10 ${isHovered ? 'z-10' : ''}

    */
  };
  return (
    <Link
      to={props.gtitle}
      className={`border border-newgrey p-4 sm:p-5 md:w-max h-max md:max-w-2xl relative mb-10 md:ml-10 ${isHovered ? 'z-10' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <h2 className='text-xl sm:text-3xl md:hidden mb-2 sm:mb-5 font-medium'>{capitalizeSentence(props.gtitle)}</h2>
      <img
        className={`object-scale-down transition filter z-10 ${isHovered ? 'md:scale-125 md:brightness-50' : ''}`}
        key={props.key}
        src={props.portrait}
        alt=""
      />
      <h2
        className={`absolute bottom-2/4 left-0 right-0 mx-auto text-3xl transition opacity-0 text-center max-w-xl ${isHovered ? 'text-white scale-125 md:opacity-100' : ''}`}
      >
        {capitalizeSentence(props.gtitle)}
      </h2>
    </Link>
  );
}

export default Gallery;
