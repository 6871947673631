import React from "react";
import instagram from "./new_assets/instagram.svg";
import linkeind from "./new_assets/linkedin.svg";

function About(props){
    return(
        <div className='m-auto md:m-0 w-11/12 md:w-full md:px-40 h-max mb-10'>
            <div className="flex flex-row items-center justify-center md:justify-start">
                <div>
                    <img className='h-48 object-scale-down' src={props.logo} alt="Cue"/>
                </div>
                <h1 className='text-8xl md:text-9xl font-black'>
                    <span>Cue</span>
                </h1>
            </div>
            <h2 className='text-5xl font-bold mt-40 mb-10'>About Me</h2>
            <p className='mb-10 text-justify'>Gage Lamberson graduated from the University of Washington with a Bachelor of Arts in Photo/Media with Honors. He has been actively studying photography since his second year of high school. However, after completing his AA at Green River College he began to feel limited by straight photography. He wanted to expand his practice at the UW. There he was given the freedom to play and experiment, eventually focusing on installation art. Through this new mode of working he found greater intentionality. He was named in the 2022 Dean’s List for his excellent academic record in the College of Arts and Sciences. In addition to his artist practice, Gage was employed as a photographer by the UW School of Art + Art History + Design. He now works at Yuen Lui Studio as a photographer and at Green River College as film photography lab tech/instructor.</p>
            <h2 className='text-5xl font-bold mt-40 mb-10'>Artist Statement</h2>
            <p className='mb-10 text-justify'>The concept that my most recent works revolve around is The Archive. I question the sanctity of The Archive and the institutions that control it. Historically, marginalized groups are left out of The Archive. The histories of racialized, non-western and Indigenous peoples are often erased from our education, replaced by a eurocentric version of history. How does this erasure come to pass? Who is erased? These are the questions I want to ask with my art. My education has adhered to eurocentricity, I want to revise my sight.</p>
            <p className='mb-10 text-justify'>The art I make is very reliant on the space I inhabit; aware of its surroundings. In my second year, my analog practice no longer had the need to be converted to digital, so I utilized traditional techniques to comment on the traditional space around me. This oppressive institution may be considered a traditional space by people in power. Utilizing photosensitive materials in a performance of Process was my place of departure. Taking apparatuses out of the darkroom and into the light was the gesture I became partial to, I applied it to a new set of concept driven works.</p>
            <div className="flex flex-row flex-wrap mt-40">
                <ul className="flex flex-col 2xl:mr-32">
                    <li className='mb-5 text-2xl font-medium'>Work Experience</li>
                    <li className='mb-5'>Film Photography Lab Tech/Instructor, Green River College</li>
                    <li className='mb-5'>Yuen Lui Studio Photographer</li>
                    <li className='mb-5'>Student Photographer, UW School of Art + Art History + Design</li>
                    <li className='mb-5'>Freelance Photographer</li>
                    <li className='mb-5'>Coach Sales Associate</li>
                    <li className='mb-5'>Amazon Fresh Associate</li>
                    <li className='mb-5'>Lowe’s Night MST Associate</li>
                    <li className='mb-5'>Darkroom Assistant, Green River College</li>
                </ul>
                <ul className="flex flex-col">
                    <li className='mb-5 text-2xl font-medium'>Exhibitions</li>
                    <li className='mb-5'>2022 BA with Honors Thesis Exhibition, Jacob Lawrence Gallery, Seattle, WA</li>
                    <li className='mb-5'>2022 Photo/Media BA Graduating Exhibition, UW Art Building, Seattle, WA</li>
                    <li className='mb-5'>2022 Student Exhibition, Parnassus Gallery, Seattle, WA</li>
                    <li className='mb-5'>2021 Student Public Projection, Hybrid Space, 1205 E Pike St, Seattle, WA</li>
                    <li className='mb-5'>2018, 2017, 2016 Student Showcase, Helen S. Smith Gallery, Green River College, Auburn, WA</li>
                </ul>
            </div>
            <h2 className='text-5xl font-bold mt-40 mb-10'>Contact Me</h2>
            <ul className="">
                <li className='mb-5 text-2xl'>gagelamberson13@gmail.com</li>
                <li className='mb-5 text-2xl'><a href="https://www.instagram.com/gage.la/?hl=en" className="flex flex-row items-center w-min"><img src={instagram} className="object-contain h-8 mr-2 brightness-0" alt="instagram"/><span>gale.la</span></a></li>
                <li className='mb-5 text-2xl'><a href="https://www.linkedin.com/in/gage-lamberson-85a0b0210/" className="flex flex-row items-center w-min"><img src={linkeind} className="object-contain h-8 mr-2 brightness-0" alt="linkedin"/><span className="whitespace-nowrap">Gage Lamberson</span></a></li>
            </ul>
        </div>
    );
}

export default About;