import React from 'react';
import Sidebar from './Sidebar.js';

function Layout(props) {


    return (
      <div className="flex flex-col md:flex-row-reverse">
        <Sidebar />
        <main className='flex flex-col align-middle w-full py-10 md:py-20 md:mr-56'>
          <div className='flex flex-col flex-wrap align-middle justify-center md:mx-10 items-start'>
            {props.children}
          </div>
        </main>
      </div>
    );
  }
  
  export default Layout;