import React from "react";

function Sections_Column(props){
    return(
        <div className="flex flex-col justify-center mb-10 space-y-40">
            {props.children}
        </div>
    );
}

export default Sections_Column;