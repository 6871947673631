import React from 'react';
import Gallery from './Gallery.js';

/*
The following code is used to extract the name of the image from the url, this is used to display the name of the image in the gallery
*/
function extractTextFromUrl(url) {
  const regex = /\/([^/.]+)\./;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  } else {
    return null; // Handle invalid URLs or no match
  }
}


function Artist(props) {


  return (
    <div className='md:w-full w-11/12 mx-auto'>
      <div className='md:px-40 w-full'>
            <div className="flex flex-row items-center justify-center md:justify-start">
                <div>
                    <img className='h-48 object-scale-down' src={props.logo} alt="Cue"/>
                </div>
                <h1 className='text-8xl md:text-9xl font-black'>
                    <span>Cue</span>
                </h1>
            </div>
        <h2 className='text-6xl md:text-7xl font-bold mt-28 mb-10'>{props.title}</h2>
      </div>
        <div className='flex flex-col md:flex-row md:flex-wrap align-middle justify-center items-center'>
            {props.list.map((image, index) => (
                <Gallery key={index} portrait={image} gtitle={extractTextFromUrl(image)} alt={`image-${index}`} />
            ))}
        </div>
    </div>
  );
}

export default Artist;