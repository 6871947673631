import React from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';

function extractTextFromPath(path) {
  const regex = /\/static\/media\/(.*)\.[a-z0-9]+$/i;
  const match = path.match(regex);
  if (match) {
    let extractedText = match[1];
    if (/(img|IMG|Img)/.test(extractedText)) {
      return '';
    }
    extractedText = extractedText.split('.')[0];
    return extractedText;
  } else {
    return null;
  }
}

function Column(props) {
  return (
      <div className='flex flex-row align-middle justify-center mb-10 items-center flex-wrap'>
        <div className='mx-4 md:mx-0 mb-10 md:mb-20 max-w-2xl w-full align-middle'>
          <h1 className='text-5xl md:text-7xl font-bold mb-5 align-middle'>{props.title}</h1>
          {props.date !== null && (
            <span className='text-2xl font-light mb-10'>{props.date}</span>
          )}
        </div>
        {props.list.map((file, index) => {
          /*Images use 1/2 of the space avalible but videos take the full width of the space avalible*/
          if (file.endsWith('.jpg')) {
            return (
              <div className='mb-5 md:ml-5 mx-4 md:mx-0'>
              <Zoom>
                <img key={index} src={file} alt='' className='md:max-w-2xl md:max-h-screen' loading="lazy"/>
              </Zoom>
                <span className='text-newgrey font-normal'>{extractTextFromPath(file)}</span>
              </div>
            );
          } else if (file.endsWith('.mp4') || file.endsWith('.mov')) {
            return (
              <div className='w-full justify-center align-middle flex m-0'>
                <video key={index} src={file} controls className='max-h-screen mb-5'>
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          } else {
            // Render a placeholder or custom component for other file types
            return (
              <div key={index}>
                File type not supported: {file}
              </div>
            );
          }
        })}
      </div>
  );
}

export default Column;
