import React from "react";

function Videos(props) {
  return (
    <div className="flex flex-col items-center justify-center mb-10 w-full">
      <div className="max-w-2xl md:mb-10">
        <h1 className="text-7xl font-bold mb-5">{props.title}</h1>
        {props.date !== null && (
          <span className="text-2xl font-light mb-10">{props.date}</span>
        )}
      </div>
      <div className="flex flex-col justify-center mx-auto w-11/12 md:mx-0 md:w-full md:px-40">
        {props.videoLinks.map((videoLink, index) => (
          <div key={index} className="my-8 md:my-16">
            <div className="aspect-video">
              <iframe
                title={`Video ${index + 1}`}
                className="w-full h-full"
                src={videoLink}
                frameBorder="0"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Videos;
